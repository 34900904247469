export function calculatePercentages(values) {
  const totalSum = values.reduce((acc, value) => acc + value, 0);

  if (totalSum === 0) {
    return values.map(() => 0);
  }

  let percentages = values.map((value) => Math.round((value / totalSum) * 100));

  const sumPercentages = percentages.reduce(
    (acc, percentage) => acc + percentage,
    0
  );
  if (sumPercentages !== 100) {
    const diff = sumPercentages - 100;
    for (let i = 0; i < percentages.length; i++) {
      if (percentages[i] > Math.abs(diff)) {
        percentages[i] -= Math.sign(diff);
        break;
      }
    }
  }

  return percentages;
}

export function currentDateTime(initDate) {
  const date = new Date(initDate);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return date
    .toLocaleString("ru-RU", options)
    .replace(",", " -")
    .replace(/\//g, ".");
}
export function formatDateToShortString(dateInitial) {
  const date = dateInitial ? new Date(dateInitial) : new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}
export const getEndDay = (day) => new Date(day.setHours(23, 59, 59, 999));

export function replaceValuesForMessages(arr) {
  const emotionMap = {
    neutral: "Нейтральный",
    positive: "Позитивный",
    negative: "Негативный",
  };

  const typeMap = {
    aggressive: "Агрессивный",
    analytical: "Аналитический",
    creative: "Креативный",
    humorous: "Юмористический",
    passiveAggressive: "Пассивный",
    reflexive: "Рефлексивный",
    supportive: "Поддерживающий",
    imperative: "Императивный",
  };

  return arr.map((item) => {
    return {
      ...item,
      emotionMessage: emotionMap[item.emotionMessage] || item.emotionMessage,
      typeMessage: typeMap[item.typeMessage] || item.typeMessage,
    };
  });
}

const getValueForСhats = (
  isFirst,
  value,
  total,
  lastWeekValue,
  lastWeekTotal
) => {
  if (isFirst) {
    return `<div>загрузка...</div>`;
  }
  const v = Number((value / total) * 100).toFixed(0);
  const lvV = Number((lastWeekValue / lastWeekTotal) * 100).toFixed(0);
  const diff = lvV - v;
  const plusMinus = lvV > v || lvV === v ? "+" : "-";
  return `<div title="${value} (${lastWeekValue})">${
    isNaN(v) ? 0 : v
  }% <span>(${plusMinus}${isNaN(diff) ? 0 : Math.abs(diff)}%)</span></div>`;
};
export const replaceValuesForСhats = (arr, isFirst = false) => {
  return arr.map((chat) => {
    return {
      ...chat,
      totalMessages: `${chat.totalMessages} <span>(${chat.lastWeek.totalMessages})</span>`,
      positive: getValueForСhats(
        isFirst,
        chat.positive,
        chat.totalMessages,
        chat.lastWeek.positive,
        chat.lastWeek.totalMessages
      ),
      neutral: getValueForСhats(
        isFirst,
        chat.neutral,
        chat.totalMessages,
        chat.lastWeek.neutral,
        chat.lastWeek.totalMessages
      ),
      negative: getValueForСhats(
        isFirst,
        chat.negative,
        chat.totalMessages,
        chat.lastWeek.negative,
        chat.lastWeek.totalMessages
      ),
      aggressive: getValueForСhats(
        isFirst,
        chat.aggressive,
        chat.totalMessages,
        chat.lastWeek.aggressive,
        chat.lastWeek.totalMessages
      ),
      analytical: getValueForСhats(
        isFirst,
        chat.analytical,
        chat.totalMessages,
        chat.lastWeek.analytical,
        chat.lastWeek.totalMessages
      ),
      creative: getValueForСhats(
        isFirst,
        chat.creative,
        chat.totalMessages,
        chat.lastWeek.creative,
        chat.lastWeek.totalMessages
      ),
      humorous: getValueForСhats(
        isFirst,
        chat.humorous,
        chat.totalMessages,
        chat.lastWeek.humorous,
        chat.lastWeek.totalMessages
      ),
      passiveAggressive: getValueForСhats(
        isFirst,
        chat.passiveAggressive,
        chat.totalMessages,
        chat.lastWeek.passiveAggressive,
        chat.lastWeek.totalMessages
      ),
      reflexive: getValueForСhats(
        isFirst,
        chat.reflexive,
        chat.totalMessages,
        chat.lastWeek.reflexive,
        chat.lastWeek.totalMessages
      ),
      supportive: getValueForСhats(
        isFirst,
        chat.supportive,
        chat.totalMessages,
        chat.lastWeek.supportive,

        chat.lastWeek.totalMessages
      ),
      imperative: getValueForСhats(
        isFirst,
        chat.imperative,
        chat.totalMessages,
        chat.lastWeek.imperative,
        chat.lastWeek.totalMessages
      ),
    };
  });
};

export const handleEmployeesBeforeView = (chats, employees) => {
  const loadingChats = chats.filter((item) => item.status_chat === "creating");
  const result = employees.map((item) => {
    if (
      item.includedChats.some((includedChat) =>
        loadingChats.find((chat) => chat.id === includedChat)
      )
    ) {
      // true это показ текста "загрузка"
      return replaceValuesForСhats([item], true)[0];
    }
    return replaceValuesForСhats([item])[0];
  });
  return result;
};
