export const tablesColumns = [
  {
    title: "Сотрудники",
    display: "employees",
    filters: ["chats", "employees"],
    showInMenu: true,
    columns: [
      { title: "Сотрудник", data: "name", className: "info", width: "7%" },
      { title: "Всего сообщений", data: "totalMessages", width: "5%" },
      {
        title: "Позитивные",
        data: "positive",
        className: "text-green",
        width: "5%",
      },
      {
        title: "Нейтральные",
        data: "neutral",
        className: "text-orange",
        width: "5%",
      },
      {
        title: "Негативные",
        data: "negative",
        className: "text-red",
        width: "5%",
      },
      { title: "Агрессивный", data: "aggressive", width: "5%" },
      {
        title: "Пассивно агрессивный",
        data: "passiveAggressive",
        width: "5%",
      },
      { title: "Аналитический", data: "analytical", width: "5%" },
      { title: "Поддерживающий", data: "supportive", width: "5%" },
      { title: "Юморной", data: "humorous", width: "5%" },
      { title: "Императивный", data: "imperative", width: "5%" },
      { title: "Творческий", data: "creative", width: "5%" },
      { title: "Рефлексивный", data: "reflexive", width: "5%" },
    ],
  },
  {
    title: "Чаты",
    display: "chats",
    filters: ["chats"],
    showInMenu: true,
    columns: [
      {
        title: "Чат / Сотрудник",
        data: "name",
        className: "info",
        width: "7%",
      },
      { title: "Всего сообщений", data: "totalMessages", width: "5%" },
      {
        title: "Позитивные",
        data: "positive",
        className: "text-green",
        width: "5%",
      },
      {
        title: "Нейтральные",
        data: "neutral",
        className: "text-orange",
        width: "5%",
      },
      {
        title: "Негативные",
        data: "negative",
        className: "text-red",
        width: "5%",
      },
      { title: "Агрессивный", data: "aggressive", width: "5%" },
      {
        title: "Пассивно агрессивный",
        data: "passiveAggressive",
        width: "5%",
      },
      { title: "Аналитический", data: "analytical", width: "5%" },
      { title: "Поддерживающий", data: "supportive", width: "5%" },
      { title: "Юморной", data: "humorous", width: "5%" },
      { title: "Императивный", data: "imperative", width: "5%" },
      { title: "Творческий", data: "creative", width: "5%" },
      { title: "Рефлексивный", data: "reflexive", width: "5%" },
    ],
  },
  {
    title: "Сообщения",
    display: "messages",
    showInMenu: false,
    filters: ["chats"],
    columns: [
      { title: "Дата", data: "date", className: "thin", width: "6%" },
      { title: "Чат", data: "chat", width: "7%" },
      {
        title: "Сообщение",
        data: "message",
        className: "thin",
        width: "45%",
      },
      { title: "Эмоц. окрас", data: "emotionalColoring", width: "10%" },
      { title: "Стиль", data: "messagesStyle", width: "10%" },
    ],
  },
];
